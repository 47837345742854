import $ from "jquery";

$(() => {
    if ($("#locations").length == 0)
        return;

    const $points = $("#locations .point");
    const $list = $("#locations #location-list");
    const $map = $("#locations #location-map");

    $points.tooltip();

    $points.each((i, el) => {
        const title = el.dataset.originalTitle || el.title;
        const category = el.dataset.category;

        const $item = $(`<li class="list-group-item">${title}</li>`);

        $(`#location-list [data-category="${category}"]`).append($item);
    });

    const map = $map[0];
    const list = $list[0];
    $<HTMLInputElement>("#locations .location-switcher").on("change", function () {
        if (this.checked) {
            map.dataset.location = this.value;
            list.dataset.location = this.value;
        }
    });
});
