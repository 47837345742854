import $ from "jquery";

$(() => {
    const intro = document.getElementsByClassName("intro")[0];

    if (!intro)
        return;

    const header = document.getElementById("header");
    const nav = document.getElementById("header-navbar");

    let sticky = false;

    const stickyHeader = () => {
        const rect = intro.getBoundingClientRect();

        if (!sticky && rect.bottom * 2 < rect.height) {
            header.className = "header header-sticky";
            nav.className = "navbar navbar-expand-md navbar-light";
            sticky = true;
        } else if (sticky && rect.bottom * 2 >= rect.height) {
            header.className = "header";
            nav.className = "navbar navbar-expand-md navbar-dark";
            sticky = false;
        }

        requestAnimationFrame(stickyHeader);
    };

    requestAnimationFrame(stickyHeader);
});
