import $ from 'jquery';

import { delay } from '../util';

async function loopTypingEffect() {
    const typeTarget = document.getElementById("intro-typing");
    const fakeCursor = document.getElementById("intro-cursor");

    const words = ["cable", "wire", "fiber"];
    let currentIndex = 0;

    while (true) {
        fakeCursor.className = "";

        const currentWord = words[currentIndex];
        for (let i = 0; i <= currentWord.length; i++) {
            await delay(100);
            typeTarget.innerText = currentWord.substring(0, i);
        }

        fakeCursor.className = "blink";

        if (currentIndex >= words.length - 1)
            currentIndex = 0;
        else
            currentIndex++;

        await delay(2000);
    }
}

$(() => {
    if ($("#intro-index").length)
        loopTypingEffect().catch(e => console.error(e));
});
