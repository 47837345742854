import "bootstrap";

import "./sections/header";
import "./sections/intro";
import "./sections/locations";

import $ from "jquery";

$(() => {
    const $viewport = $("html, body");

    $(document).on("click", "[data-scroll]", function () {
        const scrollTop = $(this.dataset.scroll).offset().top;
        $viewport.stop().animate({ scrollTop }, 1000);
    });
});
